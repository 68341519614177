import React, { useState, useEffect } from 'react';
import profileImage from './assets/images/profile.png';

// Readbi
import readbiIcon from './assets/images/apps/readbi/icon.png';
import readbiScreen1 from './assets/images/apps/readbi/screenshot1.png';
import readbiScreen2 from './assets/images/apps/readbi/screenshot2.png';

// Track
import trackIcon from './assets/images/apps/track/icon.png';
import trackScreen1 from './assets/images/apps/track/screenshot1.png';
import trackScreen2 from './assets/images/apps/track/screenshot2.png';

// Workwhere
import workwhereIcon from './assets/images/apps/workwhere/icon.png';
import workwhereScreen1 from './assets/images/apps/workwhere/screenshot1.png';
import workwhereScreen2 from './assets/images/apps/workwhere/screenshot2.png';

// Kykmenum
import kykmenuIcon from './assets/images/apps/kykmenum/icon.png';
import kykmenuScreen1 from './assets/images/apps/kykmenum/screenshot1.png';
import kykmenuScreen2 from './assets/images/apps/kykmenum/screenshot2.png';

// Dobi
import dobiIcon from './assets/images/apps/dobi/dobi.png';
import dobiScreen1 from './assets/images/apps/dobi/screenshot1.png';
import dobiScreen2 from './assets/images/apps/dobi/screenshot2.png';

// Favorite Word
import breezeVideo from './assets/videos/breeze.mp4';
import windIcon from './assets/images/wind.png';

// Back to the Future
import backToFutureImage from './assets/images/backtothefuture.png';

function ProjectSection({ name, icon, screenshot1, screenshot2, shipDate, appLink, description, isFreelance }) {
  const sectionId = name.toLowerCase().replace(/[^a-z0-9]/g, '');
  const hasAppStore = appLink && appLink.includes('apps.apple.com');
  
  return (
    <div id={sectionId} className="border border-dashed border-gray-300 rounded-[24px] px-8 md:px-12 py-8 mb-52 bg-gray-100/30 relative">
      {/* Chips Container */}
      <div className="flex justify-between items-center mb-8">
        <div className="text-caption px-3 py-1 rounded-full font-helvetica ${
          isFreelance 
            ? 'bg-green-100 text-green-600' 
            : 'bg-blue-100 text-blue-600'
        }">
          {isFreelance ? 'freelance' : 'personal'}
        </div>
        {hasAppStore && (
          <a 
            href={appLink}
            target="_blank"
            rel="noopener noreferrer"
            className="group text-caption font-helvetica text-gray-400 hover:text-gray-800 transition-all flex items-center whitespace-nowrap"
          >
            app store link <span className="inline-block transition-transform group-hover:translate-x-0.5 group-hover:-translate-y-0.5 ml-1">↗</span>
          </a>
        )}
      </div>
      
      {/* App Icon */}
      <div className="mb-4">
        <img 
          src={icon} 
          alt={`${name} icon`}
          className="w-24 h-24 mx-auto rounded-[21.33px] object-cover"
        />
      </div>

      {/* App Name */}
      <h2 className="text-headline font-helvetica text-gray-900 text-center mb-8">
        <a 
          href={appLink} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-block border-b border-black hover:text-gray-400 hover:border-transparent transition-all"
        >
          {name}
        </a>
      </h2>

      {/* Ship Date */}
      <p className="text-caption font-helvetica text-gray-500 mb-6 text-center">
        Shipped on: {shipDate}
      </p>

      {/* Description */}
      <p className="text-body font-helvetica text-gray-600 mb-12 max-w-2xl mx-auto text-center">
        {description}
      </p>

      {/* Screenshots */}
      <div className="flex flex-col md:flex-row gap-6 justify-center items-center max-w-2xl mx-auto">
        <div className="w-[60%] md:w-[35%] relative">
          <img 
            src={screenshot1} 
            alt={`${name} screenshot 1`}
            className="w-full rounded-[16px] border border-dashed border-gray-300"
          />
        </div>
        <div className="w-[60%] md:w-[35%] relative">
          <img 
            src={screenshot2} 
            alt={`${name} screenshot 2`}
            className="w-full rounded-[16px] border border-dashed border-gray-300"
          />
        </div>
      </div>
    </div>
  );
}

function SocialLinks() {
  const links = [
    { 
      name: 'Email', 
      url: 'mailto:mgirginn2@gmail.com',
      description: 'feel free to reach out'
    },
    { 
      name: '1000Kitap', 
      url: 'https://1000kitap.com/mustafagirgin',
      description: 'books i read and my thoughts'
    },
    { 
      name: 'Medium', 
      url: 'https://medium.com/@antelcha',
      description: 'occasional writings about tech and life'
    },
    { 
      name: 'Letterboxd', 
      url: 'https://letterboxd.com/mustafagw/',
      description: 'movies i watched and my ratings'
    },
    { 
      name: 'Twitter', 
      url: 'https://twitter.com/antelcha',
      description: 'random thoughts and updates'
    },
  ];

  return (
    <div className="mt-48 relative">
      <h3 className="text-subheadline font-garamond text-gray-500 mb-16 text-center">
        let's connect
      </h3>
      <div className="max-w-xl mx-auto px-8 md:px-12 text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {links.map((link) => (
            <div 
              key={link.name}
              className="group relative bg-gray-50 hover:bg-white rounded-2xl p-6 border border-gray-100 transition-all duration-300 hover:border-gray-200"
            >
              <a
                href={link.url}
                target={link.url.startsWith('#') ? '_self' : '_blank'}
                rel={link.url.startsWith('#') ? '' : 'noopener noreferrer'}
                className="block"
              >
                <div className="text-body font-helvetica text-gray-900 mb-2 group-hover:translate-x-0.5 transition-transform duration-300">
                  {link.name === 'Email' ? 'mgirginn2@gmail.com' : link.name}
                </div>
                <p className="text-caption font-helvetica text-gray-500">
                  {link.description}
                </p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function FavoriteWord() {
  const videoRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const [isIconAnimating, setIsIconAnimating] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        videoRef.current.play().catch(() => {
          console.log('Autoplay prevented');
        });
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
    // Start the icon animation slightly after the video appears
    setTimeout(() => {
      setIsIconAnimating(true);
    }, 300);
  };

  return (
    <div id="breeze" className="mt-48 relative scroll-mt-32">
      <h3 className="text-subheadline font-garamond text-gray-500 mb-16 text-center">
        a word that moves me
      </h3>
      
      <div className="relative">
        <div className="max-w-3xl mx-auto px-8 md:px-12">
          <div className="flex flex-col md:flex-row items-center gap-16">
            <div className="flex-1 text-left w-full">
              <div className="relative">
                <p className="text-title font-garamond text-gray-900 mb-4 inline-flex items-center gap-4">
                  breeze
                  <span className="inline-flex items-center">
                    <img 
                      src={windIcon} 
                      alt="Wind icon"
                      className={`
                        w-10 h-10 transition-all duration-1000 ease-in-out translate-y-[2px]
                        ${isIconAnimating ? 'opacity-60 animate-breeze' : 'opacity-0'}
                      `}
                      style={{
                        transform: isIconAnimating 
                          ? 'translateY(2px)'
                          : 'translateY(20rem) translateX(-20rem)',
                      }}
                    />
                  </span>
                </p>
              </div>
              <p className="text-subheadline font-garamond text-gray-500 mb-8">
                /briːz/
              </p>
              <div className="space-y-8">
                <div>
                  <p className="text-caption font-garamond text-gray-400 italic mb-2">noun</p>
                  <p className="text-body font-garamond text-gray-600">
                    a gentle wind that brings peace, serenity, and relief — like nature's tender caress
                  </p>
                </div>
                <div>
                  <p className="text-caption font-garamond text-gray-400 italic mb-2">verb</p>
                  <p className="text-body font-garamond text-gray-600">
                    to move swiftly and softly, as if carried by the wind's whispers
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-[45%] aspect-square rounded-3xl bg-white/90 shadow-sm overflow-hidden relative">
              {!isVideoLoaded && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
                  <img 
                    src={windIcon} 
                    alt="Wind icon"
                    className="w-16 h-16 opacity-40"
                  />
                </div>
              )}
              <video
                ref={videoRef}
                playsInline
                loop
                muted
                preload="auto"
                onLoadedData={handleVideoLoad}
                className={`w-full h-full object-cover transition-all duration-700 ${isVideoLoaded ? 'opacity-100 scale-100' : 'opacity-0 scale-105'}`}
              >
                <source src={breezeVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SelfLearner() {
  const learningJourney = [
    {
      period: "Middle School",
      description: "Discovered my passion for digital creativity, spending countless hours mastering design tools.",
      skills: [
        {
          category: "Design & Media",
          description: "Explored the world of digital art and motion graphics",
          items: ["Photoshop", "Adobe After Effects", "Cinema 4D", "Premiere Pro"]
        }
      ]
    },
    {
      period: "High School",
      description: "Expanded into music and communication, developing both artistic and interpersonal abilities.",
      skills: [
        {
          category: "Music & Arts",
          description: "Developed musical understanding from scratch",
          items: ["Guitar", "Music Theory"]
        },
        {
          category: "Language & Communication",
          description: "Built foundational soft skills that would shape my future",
          items: ["Advanced English", "Self Confidence", "Persuasion"]
        }
      ]
    },
    {
      period: "University",
      description: "Shifted focus to technology and personal development, combining creativity with logic.",
      skills: [
        {
          category: "Programming",
          description: "Self-taught programming journey, from basics to mobile development",
          items: ["Python", "Flutter", "Swift", "iOS Development"]
        },
        {
          category: "Personal Growth",
          description: "Developed habits that amplified my learning capacity",
          items: ["High Productivity", "Reading Habits"]
        }
      ]
    }
  ];

  return (
    <div className="mt-32 relative">
      <h3 className="text-subheadline font-garamond text-gray-500 mb-8 text-center">
        journey of curiosity
      </h3>
      
      <div className="max-w-3xl mx-auto space-y-8">
        {learningJourney.map((phase) => (
          <div key={phase.period} className="relative bg-gray-100/50 rounded-xl px-6 md:px-8 py-4 md:py-6 border border-dashed border-gray-200">
            <div className="relative flex flex-col items-center text-center">
              <div className="space-y-4">
                <div>
                  <div className="text-body font-helvetica font-medium text-gray-900 mb-2">
                    {phase.period}
                  </div>
                  <p className="text-caption font-helvetica text-gray-500 max-w-xl">
                    {phase.description}
                  </p>
                </div>
                
                <div className="space-y-6">
                  {phase.skills.map((skillGroup) => (
                    <div key={skillGroup.category} className="relative">
                      <div className="text-caption font-garamond italic text-gray-900/80 mb-2">
                        {skillGroup.category}
                      </div>
                      <p className="text-caption font-helvetica text-gray-500 mb-3 max-w-xl mx-auto">
                        {skillGroup.description}
                      </p>
                      <div className="flex flex-wrap gap-1 justify-center">
                        {skillGroup.items.map((skill) => (
                          <div
                            key={skill}
                            className="text-caption px-2 py-1 bg-white rounded-full font-helvetica text-gray-900 border border-gray-100"
                          >
                            {skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function BackToFutureDialog() {
  return (
    <div className="mt-48 relative">
      <div className="max-w-3xl mx-auto px-8 md:px-12">
        <img 
          src={backToFutureImage} 
          alt="Back to the Future scene"
          className="w-full rounded-2xl mb-8 border border-dashed border-gray-200"
        />
        
        <div className="space-y-6 font-garamond">
          <div>
            <p className="text-gray-400 italic mb-0.5">Jennifer</p>
            <p className="text-body">
              Doctor Brown? <span className="text-gray-400">[She takes out the Cusco FAX from her pocket, holding out for Doc to see]</span> I brought this note back from the future and...now it's erased!
            </p>
          </div>

          <div>
            <p className="text-gray-400 italic mb-0.5">Doc</p>
            <p className="text-body">
              Of course it's erased!
            </p>
          </div>

          <p className="text-body text-gray-400 italic">
            [Jennifer looks puzzled]
          </p>

          <div>
            <p className="text-gray-400 italic mb-0.5">Jennifer</p>
            <p className="text-body">
              But what does that mean?
            </p>
          </div>

          <div>
            <p className="text-gray-400 italic mb-0.5">Doc</p>
            <p className="text-body">
              <span className="border-b border-gray-300">It means that your future hasn't been written yet. No one's has. Your future is whatever you make it. So make it a good one. Both of ya!</span>
            </p>
          </div>

          <div>
            <p className="text-gray-400 italic mb-0.5">Marty II</p>
            <p className="text-body">
              <span className="text-gray-400">[Firmly, waving]</span> We will, Doc!
            </p>
          </div>

          <div>
            <p className="text-gray-400 italic mb-0.5">Doc</p>
            <p className="text-body">
              Stand back! <span className="text-gray-400">[As the doors close]</span> All right, boys, buckle up!
            </p>
          </div>

          <div>
            <p className="text-gray-400 italic mb-0.5">Marty II</p>
            <p className="text-body">
              Hey, Doc...where ya goin' now? Back to the future?
            </p>
          </div>

          <div>
            <p className="text-gray-400 italic mb-0.5">Doc</p>
            <p className="text-body">
              <span className="text-gray-400">[From the cab window, he shakes his head.]</span> Nope. Already been there! <span className="text-gray-400">[He waves at the two teenagers below]</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;1,400;1,500&display=swap');
          html, body {
            background-color: white;
          }
          html {
            scroll-behavior: smooth;
          }
          .font-garamond {
            font-family: 'EB Garamond', serif;
          }
          /* Typography System */
          .text-title {
            font-size: clamp(2.5rem, 5vw, 5rem);
            font-weight: 350;
            letter-spacing: -0.02em;
            line-height: 1.1;
          }
          .text-headline {
            font-size: clamp(1.75rem, 3vw, 2rem);
            font-weight: 350;
            letter-spacing: -0.02em;
            line-height: 1.2;
          }
          .text-subheadline {
            font-size: 1.1rem;
            font-style: italic;
            line-height: 1.4;
          }
          .text-body {
            font-size: 15px;
            font-weight: 350;
            line-height: 1.6;
          }
          .text-caption {
            font-size: 13px;
            font-weight: 350;
            line-height: 1.4;
          }
        `}
      </style>
      <div className="min-h-screen bg-white selection:bg-blue-900/10 selection:text-blue-900">
        <div className="max-w-4xl mx-auto px-8 md:px-12 pt-48 pb-24">
          <div className="text-center mb-32">
            <img 
              src={profileImage} 
              alt="Mustafa Girgin"
              className="w-32 h-32 md:w-40 md:h-40 rounded-full mx-auto mb-12 object-cover"
            />
            <h1 className="text-title font-helvetica text-gray-900 mb-8">
              Mustafa Girgin
            </h1>
            <p className="text-subheadline font-garamond text-gray-500 max-w-xl mx-auto">
              reader, writer, coder, designer, thinker, builder, enjoyer
            </p>
          </div>

          <div className="space-y-32 mb-32">
            <ProjectSection 
              name="Dobi"
              icon={dobiIcon}
              screenshot1={dobiScreen1}
              screenshot2={dobiScreen2}
              shipDate="December 2024"
              appLink="https://apps.apple.com/tr/app/dobi/id6738885067"
              description="An educational app built with Flutter that helps children learn values through simple gamification. Inspired by Duolingo's approach, it offers interactive lessons and progress tracking to make learning more engaging."
              isFreelance={true}
            />
            <ProjectSection 
              name="KYKMenum"
              icon={kykmenuIcon}
              screenshot1={kykmenuScreen1}
              screenshot2={kykmenuScreen2}
              shipDate="September 2024"
              appLink="https://apps.apple.com/tr/app/kyk-menüm/id6714449526"
              description="A SwiftUI app that shows daily menus for government dormitory students. It includes helpful features like meal planning with friends and support for multiple cities, with simple Google or Apple sign-in options."
              isFreelance={false}
            />
            <ProjectSection 
              name="Workwhere"
              icon={workwhereIcon}
              screenshot1={workwhereScreen1}
              screenshot2={workwhereScreen2}
              shipDate="March 2024"
              appLink="https://apps.apple.com/tr/app/workwhere/id6478559851"
              description="A SwiftUI app that helps remote workers find good places to work. You can discover cafes and workspaces based on your preferences for quietness, fresh air, and coffee quality."
              isFreelance={false}
            />
            <ProjectSection 
              name="Track!"
              icon={trackIcon}
              screenshot1={trackScreen1}
              screenshot2={trackScreen2}
              shipDate="September 2023"
              appLink="https://apps.apple.com/tr/app/track/id6471897749"
              description="A simple SwiftUI app for tracking different aspects of your life on a 0 to 1 scale. It started as a learning project and grew into a useful tool for monitoring habits and personal metrics."
              isFreelance={false}
            />
            <ProjectSection 
              name="ReadBi"
              icon={readbiIcon}
              screenshot1={readbiScreen1}
              screenshot2={readbiScreen2}
              shipDate="August 2023"
              appLink="https://apps.apple.com/tr/app/readbi/id6468990192"
              description="A UIKit app that makes bilingual reading easier. It lets you translate words or phrases with a simple selection, helping you read in different languages without constantly switching between apps or dictionaries."
              isFreelance={false}
            />
          </div>

          
          <SelfLearner />
          <FavoriteWord />
          <SocialLinks />
          <BackToFutureDialog />
        </div>
      </div>
    </>
  );
}

export default App;
