import React, { useState, useEffect } from 'react';
import profileImage from './assets/images/profile.png';

// Readbi
import readbiIcon from './assets/images/apps/readbi/icon.png';
import readbiScreen1 from './assets/images/apps/readbi/screenshot1.png';
import readbiScreen2 from './assets/images/apps/readbi/screenshot2.png';

// Track
import trackIcon from './assets/images/apps/track/icon.png';
import trackScreen1 from './assets/images/apps/track/screenshot1.png';
import trackScreen2 from './assets/images/apps/track/screenshot2.png';

// Workwhere
import workwhereIcon from './assets/images/apps/workwhere/icon.png';
import workwhereScreen1 from './assets/images/apps/workwhere/screenshot1.png';
import workwhereScreen2 from './assets/images/apps/workwhere/screenshot2.png';

// Kykmenum
import kykmenuIcon from './assets/images/apps/kykmenum/icon.png';
import kykmenuScreen1 from './assets/images/apps/kykmenum/screenshot1.png';
import kykmenuScreen2 from './assets/images/apps/kykmenum/screenshot2.png';

// Dobi
import dobiIcon from './assets/images/apps/dobi/dobi.png';
import dobiScreen1 from './assets/images/apps/dobi/screenshot1.png';
import dobiScreen2 from './assets/images/apps/dobi/screenshot2.png';

// Favorite Word
import breezeVideo from './assets/videos/breeze.mp4';
import windIcon from './assets/images/wind.png';


function ProjectSection({ name, icon, screenshot1, screenshot2, shipDate, appLink, description, isFreelance }) {
  const sectionId = name.toLowerCase().replace(/[^a-z0-9]/g, '');
  const hasAppStore = appLink && appLink.includes('apps.apple.com');
  
  return (
    <div id={sectionId} className="border border-dashed border-gray-300 rounded-[24px] p-8 mb-52 bg-gray-100/30 relative">
      {/* Chips Container */}
      <div className="flex justify-between items-center mb-8">
        <div className={`px-3 py-1 rounded-full text-[13px] font-helvetica font-[350] ${
          isFreelance 
            ? 'bg-green-100 text-green-600' 
            : 'bg-blue-100 text-blue-600'
        }`}>
          {isFreelance ? 'freelance' : 'personal'}
        </div>
        {hasAppStore && (
          <a 
            href={appLink}
            target="_blank"
            rel="noopener noreferrer"
            className="group text-[13px] font-helvetica font-[350] text-gray-400 hover:text-gray-800 transition-all flex items-center whitespace-nowrap"
          >
            app store link <span className="inline-block transition-transform group-hover:translate-x-0.5 group-hover:-translate-y-0.5 ml-1">↗</span>
          </a>
        )}
      </div>
      
      {/* App Icon */}
      <div className="mb-4">
        <img 
          src={icon} 
          alt={`${name} icon`}
          className="w-24 h-24 mx-auto rounded-[21.33px] object-cover"
        />
      </div>

      {/* App Name */}
      <h2 className="text-2xl font-helvetica font-[350] tracking-[-0.02em] text-gray-900 text-center mb-8 leading-[1.2]">
        <a 
          href={appLink} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-block border-b border-black hover:text-gray-400 hover:border-transparent transition-all"
        >
          {name}
        </a>
      </h2>

      {/* Ship Date */}
      <p className="text-[13px] font-helvetica font-[350] text-gray-500 mb-6 text-center">
        Shipped on: {shipDate}
      </p>

      {/* Description */}
      <p className="text-[15px] font-helvetica font-[350] text-gray-600 mb-12 max-w-2xl mx-auto leading-[1.6] text-center">
        {description}
      </p>

      {/* Screenshots */}
      <div className="flex flex-col md:flex-row gap-6 justify-center items-center max-w-2xl mx-auto">
        <div className="w-[60%] md:w-[35%] relative">
          <img 
            src={screenshot1} 
            alt={`${name} screenshot 1`}
            className="w-full rounded-[16px] border border-dashed border-gray-300"
          />
        </div>
        <div className="w-[60%] md:w-[35%] relative">
          <img 
            src={screenshot2} 
            alt={`${name} screenshot 2`}
            className="w-full rounded-[16px] border border-dashed border-gray-300"
          />
        </div>
      </div>
    </div>
  );
}

function SocialLinks() {
  const links = [
    { 
      name: 'Email', 
      url: 'mailto:mgirginn2@gmail.com',
      description: 'feel free to reach out'
    },
    { 
      name: 'Breeze', 
      url: '#breeze',
      description: 'my favorite word and its meaning'
    },
    { 
      name: '1000Kitap', 
      url: 'https://1000kitap.com/mustafagirgin',
      description: 'books i read and my thoughts'
    },
    { 
      name: 'Medium', 
      url: 'https://medium.com/@antelcha',
      description: 'occasional writings about tech and life'
    },
    { 
      name: 'Letterboxd', 
      url: 'https://letterboxd.com/mustafagw/',
      description: 'movies i watched and my ratings'
    },
    { 
      name: 'Twitter', 
      url: 'https://twitter.com/antelcha',
      description: 'random thoughts and updates'
    },
  ];

  return (
    <div className="border text-center border-dashed border-gray-300 rounded-[24px] p-8 bg-gray-50/50">
      <div className="flex flex-col divide-y divide-dashed divide-gray-200">
        {links.map((link) => (
          <div key={link.name} className="py-6 first:pt-0 last:pb-0">
            <a
              href={link.url}
              target={link.url.startsWith('#') ? '_self' : '_blank'}
              rel={link.url.startsWith('#') ? '' : 'noopener noreferrer'}
              className="text-[15px] font-helvetica font-[350] text-gray-900 border-b border-black hover:text-gray-400 hover:border-transparent transition-all"
            >
              {link.name === 'Email' ? 'mgirginn2@gmail.com' : link.name}
            </a>
            <p className="text-[13px] font-helvetica font-[350] text-gray-500 mt-2">
              {link.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function FavoriteWord() {
  const videoRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const [isIconAnimating, setIsIconAnimating] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        videoRef.current.play().catch(() => {
          console.log('Autoplay prevented');
        });
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
    // Start the icon animation slightly after the video appears
    setTimeout(() => {
      setIsIconAnimating(true);
    }, 300);
  };

  return (
    <div id="breeze" className="mt-32 relative scroll-mt-32">
      <div className="absolute inset-0" style={{ marginLeft: 'calc(-50vw + 50%)', marginRight: 'calc(-50vw + 50%)', width: '100vw' }} />
      <div className="relative pt-32 pb-32">
        <div className="max-w-2xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center gap-16">
            <div className="flex-1 text-left w-full">
              <h3 className="text-[1.1rem] font-garamond italic tracking-wide text-blue-900/70 mb-2">
                favorite word
              </h3>
              <div className="relative">
                <p className="text-[3rem] md:text-[4rem] font-garamond tracking-wide text-blue-900 leading-[1.1] mb-3 inline-flex items-center gap-4">
                  breeze
                  <span className="inline-flex items-center">
                    <img 
                      src={windIcon} 
                      alt="Wind icon"
                      className={`
                        w-8 h-8 transition-all duration-1000 ease-in-out translate-y-[2px]
                        ${isIconAnimating ? 'opacity-50 animate-breeze' : 'opacity-0'}
                      `}
                      style={{
                        transform: isIconAnimating 
                          ? 'translateY(2px)'
                          : 'translateY(20rem) translateX(-20rem)',
                      }}
                    />
                  </span>
                </p>
              </div>
              <p className="text-[0.9rem] font-garamond text-blue-700/90 italic mb-6">
                /briːz/
              </p>
              <div className="space-y-6">
                <div>
                  <p className="text-[0.9rem] font-garamond text-blue-700/80 italic mb-1">noun</p>
                  <p className="text-[1rem] font-garamond text-blue-700 leading-relaxed">
                    a gentle wind that brings peace, serenity, and relief — like nature's tender caress
                  </p>
                </div>
                <div>
                  <p className="text-[0.9rem] font-garamond text-blue-700/80 italic mb-1">verb</p>
                  <p className="text-[1rem] font-garamond text-blue-700 leading-relaxed">
                    to move swiftly and softly, as if carried by the wind's whispers
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-[45%] aspect-square rounded-2xl bg-white/90 shadow-sm overflow-hidden relative">
              {!isVideoLoaded && (
                <div className="absolute inset-0 flex items-center justify-center bg-blue-50">
                  <img 
                    src={windIcon} 
                    alt="Wind icon"
                    className="w-16 h-16 opacity-50"
                  />
                </div>
              )}
              <video
                ref={videoRef}
                playsInline
                loop
                muted
                preload="auto"
                onLoadedData={handleVideoLoad}
                className={`w-full h-full object-cover transition-opacity duration-300 ${isVideoLoaded ? 'opacity-100' : 'opacity-0'}`}
              >
                <source src={breezeVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;1,400;1,500&display=swap');
          html, body {
            background-color: white;
          }
          html {
            scroll-behavior: smooth;
          }
          .font-garamond {
            font-family: 'EB Garamond', serif;
          }
        `}
      </style>
      <div className="min-h-screen bg-white selection:bg-blue-900/10 selection:text-blue-900">
        <div className="max-w-4xl mx-auto px-4 pt-48 pb-24">
          <div className="text-center mb-32">
            <img 
              src={profileImage} 
              alt="Mustafa Girgin"
              className="w-32 h-32 md:w-40 md:h-40 rounded-full mx-auto mb-12 object-cover"
            />
            <h1 className="text-[2.5rem] md:text-[5rem] font-helvetica font-[350] tracking-[-0.02em] text-gray-900 leading-[1.1] mb-8">
              Mustafa Girgin
            </h1>
            <p className="text-[17px] font-helvetica font-[350] text-gray-500 max-w-xl mx-auto leading-[1.6]">
              reader, writer, coder, designer, thinker, builder, enjoyer
            </p>
          </div>

          <div className="space-y-32 mb-32">
            <ProjectSection 
              name="Dobi"
              icon={dobiIcon}
              screenshot1={dobiScreen1}
              screenshot2={dobiScreen2}
              shipDate="December 2024"
              appLink="https://apps.apple.com/tr/app/dobi/id6738885067"
              description="An educational app built with Flutter that helps children learn values through simple gamification. Inspired by Duolingo's approach, it offers interactive lessons and progress tracking to make learning more engaging."
              isFreelance={true}
            />
            <ProjectSection 
              name="KYKMenum"
              icon={kykmenuIcon}
              screenshot1={kykmenuScreen1}
              screenshot2={kykmenuScreen2}
              shipDate="September 2024"
              appLink="https://apps.apple.com/tr/app/kyk-menüm/id6714449526"
              description="A SwiftUI app that shows daily menus for government dormitory students. It includes helpful features like meal planning with friends and support for multiple cities, with simple Google or Apple sign-in options."
              isFreelance={false}
            />
            <ProjectSection 
              name="Workwhere"
              icon={workwhereIcon}
              screenshot1={workwhereScreen1}
              screenshot2={workwhereScreen2}
              shipDate="March 2024"
              appLink="https://apps.apple.com/tr/app/workwhere/id6478559851"
              description="A SwiftUI app that helps remote workers find good places to work. You can discover cafes and workspaces based on your preferences for quietness, fresh air, and coffee quality."
              isFreelance={false}
            />
            <ProjectSection 
              name="Track!"
              icon={trackIcon}
              screenshot1={trackScreen1}
              screenshot2={trackScreen2}
              shipDate="September 2023"
              appLink="https://apps.apple.com/tr/app/track/id6471897749"
              description="A simple SwiftUI app for tracking different aspects of your life on a 0 to 1 scale. It started as a learning project and grew into a useful tool for monitoring habits and personal metrics."
              isFreelance={false}
            />
            <ProjectSection 
              name="ReadBi"
              icon={readbiIcon}
              screenshot1={readbiScreen1}
              screenshot2={readbiScreen2}
              shipDate="August 2023"
              appLink="https://apps.apple.com/tr/app/readbi/id6468990192"
              description="A UIKit app that makes bilingual reading easier. It lets you translate words or phrases with a simple selection, helping you read in different languages without constantly switching between apps or dictionaries."
              isFreelance={false}
            />
          </div>

          <SocialLinks />
          <FavoriteWord />
        </div>
      </div>
    </>
  );
}

export default App;
